<template>
  <form-field-text
    v-model="docId"
    :disabled="loading || disabled"
    :hint="isRUT && !hideLoadBtn ? $t('form.field.rut') : undefined"
    :loading="loading"
    :mask="mask"
    :persistent-hint="isRUT && !hideLoadBtn"
    :required="required"
    :rules="rules"
    label="doc.id"
    @input="onInput"
  >
    <template v-if="isRUT && !hideLoadBtn" #append>
      <v-btn
        :disabled="!valid"
        color="primary"
        depressed
        small
        @click="loadFirmData"
        v-text="'DGI'"
      />
    </template>
  </form-field-text>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import useRUT from "@/composables/rut";
import { FlashModule } from "@/store/flash";
import { toCode } from "@/plugins/helpers";
import type { IDListKey } from "@/services/Utils";
import { get, isNil, isString, set } from "lodash";

@Component
export default class FormFieldDocId extends Vue {
  @VModel({ type: String }) docId!: string;
  @Prop(String) readonly docType!: IDListKey;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly hideLoadBtn!: boolean;

  loading = false;

  get isRUT() {
    return this.docType === "rut";
  }

  get mask() {
    let sMask = "";

    switch (this.docType) {
      case "ci":
        sMask = "#.###.###-#";
        break;

      case "rut":
        sMask = "##-######-###-#";
        break;
    }

    return sMask;
  }

  get rules() {
    return ["rut", "ci"].includes(this.docType) && !this.disabled
      ? this.docType
      : "";
  }

  get valid() {
    if (!this.isRUT) {
      return false;
    }

    return useRUT(this.docId).validate();
  }

  async loadFirmData() {
    if (!this.isRUT) {
      return;
    }

    const obRut = useRUT(this.docId);
    if (!obRut.validate()) {
      return;
    }

    this.loading = true;
    const obFirm = await obRut.loadFirmData(true);

    if (isString(obFirm)) {
      switch (obFirm) {
        case "Username is required":
          FlashModule.error(this.$t("dgi.rut.badcredentials"));
          break;
      }
      this.loading = false;
      return;
    }

    const sName: string | undefined = get(obFirm, "name");
    this.loading = false;

    if (isNil(obFirm)) {
      FlashModule.info(this.$t("dgi.rut.emptyresponse"));
      return;
    }

    if (sName) {
      set(obFirm, "code", toCode(sName));
    }

    this.$emit("load", obFirm);
  }

  onInput(sValue: any) {
    this.$emit("input", sValue);
  }
}
</script>
